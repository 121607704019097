<template>
  <div class="elite-tabs-wrapper-content">
    <div class="title-header">
      <div class="row">
        <div class="col-6">
          <h3>{{ contactForm.subject }}</h3>
        </div>

        <div class="col-6 text-right">
          <base-button
            class="btn-outline-danger"
            icon
            size="sm"
            v-if="$currentUserCan($permissions.PERM_DELETE_CONTACT_FORMS)"
            @click="deleteContactForm"
          >
            <span class="btn-inner--icon">
              <i class="far fa-trash-alt"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("COMMON.DELETE") }}
            </span>
          </base-button>

          <base-button
            class="elite-button add"
            icon
            size="sm"
            v-if="$currentUserCan($permissions.PERM_EDIT_CONTACT_FORMS)"
            @click="editContactForm"
          >
            <span class="btn-inner--icon">
              <i class="far fa-edit"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("COMMON.EDIT") }}
            </span>
          </base-button>
        </div>
      </div>
    </div>

    <div class="all-infos">
      <div class="all-infos-text">
        <dl class="row">
          <dt>{{ $t("COMMON.FIRSTNAME_LASTNAME") }}</dt>
          <dd>
            {{ `${contactForm.firstname} ${contactForm.lastname}` }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.EMAIL") }}</dt>
          <dd>
            {{ contactForm.email }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.PHONE") }}</dt>
          <dd>
            {{ contactForm.phone }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("TEMPLATES_MAILS.SUBJECT") }}</dt>
          <dd>
            {{ contactForm.subject }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("TEMPLATES_MAILS.CONTENT") }}</dt>
          <dd>
            {{ contactForm.message }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("CONTACT_FORMS.SUBSCRIBED_TO_NEWSLETTER") }}</dt>
          <dd>
            {{
              $t(
                "COMMON." + (contactForm.subscribe_to_newsletter ? "YES" : "NO")
              )
            }}
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import {
  VEHICLE_STATUS_COMPLETED,
  VEHICLE_AUCTION_STATUS_SOON,
  VEHICLE_AUCTION_STATUS_ONGOING,
  VEHICLE_AUCTION_STATUS_COMPLETED,
  VEHICLE_SALE_CONFIRMATION_STATUS_PENDING,
  VEHICLE_SALE_CONFIRMATION_STATUS_CONFIRMED,
  VEHICLE_SALE_CONFIRMATION_STATUS_CANCELED,
} from "@/constants/vehicles";
import { AUCTION_TYPE_EVALUATION } from "@/constants/auctions";
import { QUERY_ACTIONS_VIEW } from "@/constants/common";

export default {
  name: "contact-form-view-global",

  components: {},

  props: ["contactForm"],

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {
    editContactForm() {
      this.$emit("onEditContactForm", this.contactForm);
    },
    deleteContactForm() {
      this.$emit("onDeleteContactForm", this.contactForm);
    },
  },

  mounted() {},

  watch: {
    contactForm(contactForm) {},
  },
};
</script>
