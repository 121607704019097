<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!contactForm">
      <span class="loader"></span>
    </span>

    <div v-if="contactForm" class="elite-tabs-wrapper">
      <div class="tabs-wrapper">
        <tabs
          fill
          class="flex-column flex-md-row"
          tabNavWrapperClasses="nav-wrapper tabs-wrapper-nav"
          tabNavClasses="nav elite-tabs"
          value="global"
        >
          <tab-pane title="global" id="1" :active="true">
            <span slot="title">
              <i class="ni ni-cloud-upload-96"></i>
              {{ $t("COMMON.GLOBAL") }}
            </span>
            <contact-form-view-global
              :contactForm="contactForm"
              @onEditContactForm="editContactForm"
              @onDeleteContactForm="deleteContactForm"
            />
          </tab-pane>

          <tab-pane
            title="logs"
            id="999"
            v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOGS)"
          >
            <span slot="title">
              <i class="fa fa-file"></i>
              {{ $t("COMMON.LOGS") }}
            </span>
            <contact-form-view-logs :contactForm="contactForm" />
          </tab-pane>

          <tab-pane
            title="requests"
            id="1000"
            v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOGS)"
          >
            <span slot="title">
              <i class="fa fa-file"></i>
              {{ $t("COMMON.LOGS") }}
            </span>
            <contact-form-view-requests :contactForm="contactForm" />
          </tab-pane>
        </tabs>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";
import { Tabs, TabPane } from "@/components";
import ContactFormViewGlobal from "../partials/ContactFormViewGlobal.vue";
import ContactFormViewLogs from "../partials/ContactFormViewLogs.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    Tabs,
    TabPane,
    ContactFormViewGlobal,
    ContactFormViewLogs,
  },

  mixins: [requestErrorMixin],

  props: {
    contactFormId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      contactForm: null,
    };
  },

  computed: {},

  watch: {},

  created() {
    this.get();
  },

  methods: {
    async get() {
      try {
        await this.$store.dispatch("contactForms/get", this.contactFormId);
        this.contactForm = this.$store.getters["contactForms/contactForm"];
      } catch (error) {
        this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
    editContactForm(vehicle) {
      this.$emit("onEditContactForm", vehicle);
    },
    deleteContactForm(vehicle) {
      this.$emit("onDeleteContactForm", vehicle);
    },
  },
};
</script>
