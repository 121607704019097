<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!contactForm">
      <span class="loader"></span>
    </span>

    <contact-form-form
      ref="form"
      v-if="contactForm"
      :loading="loading"
      :contact-form-data="contactForm"
      :form-errors="formErrors"
      @contactFormSubmitted="handleSubmit"
      @formChanged="() => (alertLeave = true)"
    />
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";
import ContactFormForm from "../partials/ContactFormForm.vue";
import requestErrorMixin from "@/mixins/request-error-mixin";

export default {
  layout: "DashboardLayout",

  components: { ContactFormForm },

  mixins: [alertLeave, requestErrorMixin],

  props: {
    contactFormId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      contactForm: null,
      formErrors: null,
      loading: false,
    };
  },

  created() {
    console.log("watch created");

    this.get();
  },

  methods: {
    async get() {
      this.loading = true;

      try {
        await this.$store.dispatch("contactForms/get", this.contactFormId);

        this.contactForm = this.$store.getters["contactForms/contactForm"];

        this.loading = false;
      } catch (error) {
        console.warn("error", error);

        this.showRequestError(error);

        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });

        this.loading = false;
      }
    },
    async handleSubmit(contactForm) {
      this.loading = true;

      const contactFormData = cloneDeep(contactForm);

      try {
        await this.$store.dispatch("contactForms/update", contactFormData);

        this.$notify({
          type: "success",
          message: this.$t("CONTACT_FORMS.CONTACT_FORM_UPDATED"),
        });

        this.contactForm = this.$store.getters["contactForms/contactForm"];

        this.$emit("onViewContactForm", this.contactForm, true);

        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });

        this.formErrors = error.response?.data?.errors ?? {};

        this.loading = false;
      }
    },
  },
};
</script>
