<template>
  <form
    class="add-form"
    ref="profile_form"
    @submit.prevent="handleSubmit"
    @keydown.enter.prevent="() => {}"
  >
    <div class="form-wrapper full">
      <base-input
        :label="`${$t('COMMON.LASTNAME')} (*)`"
        :placeholder="$t('COMMON.LASTNAME')"
        v-model="contactForm.lastname"
        @change="onFormChanged"
      />
      <validation-error :errors="apiValidationErrors.lastname" />
    </div>

    <div class="form-wrapper full">
      <base-input
        :label="`${$t('COMMON.FIRSTNAME')} (*)`"
        :placeholder="$t('COMMON.FIRSTNAME')"
        v-model="contactForm.firstname"
        @change="onFormChanged"
      />
      <validation-error :errors="apiValidationErrors.firstname" />
    </div>

    <div class="form-wrapper full">
      <base-input
        :label="`${$t('COMMON.EMAIL')} (*)`"
        :placeholder="$t('COMMON.EMAIL')"
        v-model="contactForm.email"
        @change="onFormChanged"
      />
      <validation-error :errors="apiValidationErrors.email" />
    </div>

    <div class="form-wrapper full">
      <base-input :label="`${$t('COMMON.PHONE')} (*)`">
        <phone-number-input
          :phoneNumber="contactForm.phone"
          @phoneNumberChanged="
            (phone) => {
              contactForm.phone = phone;
              onFormChanged();
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.phone" />
    </div>

    <div class="form-wrapper full">
      <base-input
        :label="`${$t('TEMPLATES_MAILS.SUBJECT')} (*)`"
        :placeholder="$t('TEMPLATES_MAILS.SUBJECT')"
        v-model="contactForm.subject"
        @change="onFormChanged"
      />
      <validation-error :errors="apiValidationErrors.subject" />
    </div>

    <div class="form-wrapper full">
      <base-input
        type="textarea"
        :label="`${$t('TEMPLATES_MAILS.CONTENT')} (*)`"
        :placeholder="$t('TEMPLATES_MAILS.CONTENT')"
      >
        <textarea
          v-model="contactForm.message"
          :placeholder="$t('TEMPLATES_MAILS.CONTENT')"
          class="form-control"
          rows="3"
        ></textarea>
      </base-input>
      <validation-error :errors="apiValidationErrors.message" />
    </div>

    <div class="submit-wrapper">
      <base-button
        type="button"
        class="btn btn-sm elite-submit"
        native-type="submit"
        :disabled="loading"
      >
        <i class="fas fa-spinner fa-spin" v-if="loading" />
        {{
          contactForm.id
            ? $t("CONTACT_FORMS.EDIT_CONTACT_FORM")
            : $t("CONTACT_FORMS.ADD_CONTACT_FORM")
        }}
      </base-button>
    </div>
  </form>
</template>

<script>
import { cloneDeep } from "lodash";
import { Option, Select } from "element-ui";
import formMixin from "@/mixins/form-mixin";
import PhoneNumberInput from "@/components/PhoneNumberInput.vue";
import ValidationError from "@/components/ValidationError.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

export default {
  layout: "DashboardLayout",

  components: {
    flatPicker,
    BaseInput,
    ValidationError,
    PhoneNumberInput,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin],

  props: ["contactFormData", "formErrors", "loading"],

  data() {
    let contactFormData = { ...this.contactFormData };
    return {
      contactForm: contactFormData,
    };
  },

  created() {},

  methods: {
    async handleSubmit() {
      let contactFormData = cloneDeep(this.contactForm);
      this.$emit("contactFormSubmitted", contactFormData);
    },

    onFormChanged() {
      this.$emit("formChanged");
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    contactFormData(contactFormData) {
      if (contactFormData) {
        this.contactForm = {
          ...this.contactForm,
          ...cloneDeep(contactFormData),
        };
      }
    },
  },
};
</script>
