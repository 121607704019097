<template>
  <div class="container-fluid">
    <div class="page-wrapper">
      <div slot="header" class="page-wrapper-header">
        <div class="row">
          <div class="col-6">
            <h3 class="mb-0">{{ $t("CONTACT_FORMS.CONTACT_FORM_LIST") }}</h3>
          </div>

          <div class="col-6 text-right">
            <base-button
              class="elite-button add"
              icon
              size="sm"
              @click="openContactFormCreateModal"
              v-if="$currentUserCan($permissions.PERM_CREATE_CONTACT_FORMS)"
            >
              <span class="btn-inner--icon">
                <i class="far fa-plus-circle"></i>
              </span>
              <span class="btn-inner--text">
                {{ $t("CONTACT_FORMS.ADD_CONTACT_FORM") }}
              </span>
            </base-button>

            <notification-subscription
              v-if="$currentUserCan($permissions.PERM_VIEW_CONTACT_FORMS)"
              :objectType="'contactForms'"
              :events="{
                CREATE: $t('NOTIFICATIONS.EVENT_CREATE'),
                UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
              }"
            />
          </div>
        </div>
      </div>

      <contact-form-list-table
        @onAddContactForm="openContactFormCreateModal"
        @onViewContactForm="openContactFormViewModal"
        @onEditContactForm="openContactFormEditModal"
        @onDeleteContactForm="deleteContactForm"
        :key="renderKey * 100"
      />

      <div
        v-if="isViewContactFormModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isViewContactFormModalOpened ? 'show' : 'hide']"
        :key="renderKey * 200"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'CONTACT_FORM_VIEW'"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <button @click="closeContactFormModal">
                <i class="far fa-arrow-left"></i>
              </button>
              <h1>
                {{ $t("CONTACT_FORMS.VIEW_CONTACT_FORM") }}
              </h1>
            </div>

            <div class="resizable-wrapper-content-header-right">
              <notification-subscription
                v-if="openContactForm"
                :objectType="'contactForms'"
                :objectId="openContactForm.id"
                :events="{
                  UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                  DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
                }"
              />

              <button class="expand" id="expand-button">
                <img
                  src="../../../../../public/img/e-arrows-expand.svg"
                  alt="icon"
                />
              </button>
            </div>
          </div>

          <div class="resizable-wrapper-content-body">
            <view-contact-form-page
              v-if="openContactForm"
              :contactFormId="openContactForm.id"
              @onEditContactForm="openContactFormEditModal"
              @onDeleteContactForm="deleteContactForm"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isEditContactFormModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isEditContactFormModalOpened ? 'show' : 'hide']"
        :key="renderKey * 300"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'CONTACT_FORM'"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <button @click="closeContactFormModal">
                <i class="far fa-arrow-left"></i>
              </button>
              <h1>
                {{ $t("CONTACT_FORMS.EDIT_CONTACT_FORM") }}
              </h1>
            </div>

            <div class="resizable-wrapper-content-header-right">
              <base-dropdown
                title-classes="btn dropdown-button"
                menu-on-right
                :has-toggle="false"
              >
                <template slot="title">
                  <i class="fas fa-ellipsis-v"></i>
                </template>

                <button
                  class="view"
                  @click="openContactFormViewModal(openContactForm)"
                >
                  <i class="fal fa-expand-alt"></i>
                  <span>{{ $t("COMMON.VIEW") }}</span>
                </button>
              </base-dropdown>

              <button class="expand" id="expand-button">
                <img
                  src="../../../../../public/img/e-arrows-expand.svg"
                  alt="icon"
                />
              </button>
            </div>
          </div>

          <div class="resizable-wrapper-content-body">
            <edit-contact-form-page
              v-if="openContactForm"
              :contactFormId="openContactForm.id"
              @onViewContactForm="openContactFormViewModal"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isAddContactFormModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isAddContactFormModalOpened ? 'show' : 'hide']"
        :key="renderKey * 400"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'CONTACT_FORM'"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <button @click="closeContactFormModal">
                <i class="far fa-arrow-left"></i>
              </button>
              <h1>
                {{ $t("CONTACT_FORMS.ADD_CONTACT_FORM") }}
              </h1>
            </div>

            <div class="resizable-wrapper-content-header-right">
              <button class="expand" id="expand-button">
                <img
                  src="../../../../../public/img/e-arrows-expand.svg"
                  alt="icon"
                />
              </button>
            </div>
          </div>

          <div class="resizable-wrapper-content-body">
            <add-contact-form-page
              @onViewContactForm="openContactFormViewModal"
              @onEditContactForm="openContactFormEditModal"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import swal from "sweetalert2";
import { Button } from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";
import {
  QUERY_ACTIONS_ADD,
  QUERY_ACTIONS_EDIT,
  QUERY_ACTIONS_VIEW,
} from "@/constants/common";
import NotificationSubscription from "@/components/NotificationSubscription.vue";
import ContactFormListTable from "./partials/ContactFormListTable.vue";
import EditContactFormPage from "./components/EditContactFormComponent.vue";
import AddContactFormPage from "./components/AddContactFormComponent.vue";
import ViewContactFormPage from "./components/ViewContactFormComponent.vue";

export default {
  layout: "DashboardLayout",

  components: {
    ContactFormListTable,
    NotificationSubscription,
    EditContactFormPage,
    AddContactFormPage,
    ViewContactFormPage,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  computed: {},

  watch: {},

  data() {
    const contactFormId = this.$route.query.id;
    const action = this.$route.query.action;
    let isViewContactFormModalOpened = false;
    let isEditContactFormModalOpened = false;
    let isAddContactFormModalOpened = false;
    let openContactForm = null;
    if (contactFormId && action) {
      if (action === QUERY_ACTIONS_VIEW) {
        isViewContactFormModalOpened = true;
      } else if (action === QUERY_ACTIONS_EDIT) {
        isEditContactFormModalOpened = true;
      }
      openContactForm = { id: contactFormId };
    } else if (action === QUERY_ACTIONS_ADD) {
      isAddContactFormModalOpened = true;
    }
    return {
      isViewContactFormModalOpened: isViewContactFormModalOpened,
      isEditContactFormModalOpened: isEditContactFormModalOpened,
      isAddContactFormModalOpened: isAddContactFormModalOpened,
      openContactForm: openContactForm,
      renderKey: 1,
    };
  },

  methods: {
    openContactFormCreateModal() {
      this.closeContactFormModal();
      this.isAddContactFormModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List ContactForms",
          query: { action: QUERY_ACTIONS_ADD },
        }).href
      );
    },
    openContactFormViewModal(vehicle, reRender = false) {
      this.closeContactFormModal();
      this.openContactForm = vehicle;
      this.isViewContactFormModalOpened = true;
      if (reRender) {
        this.renderKey++;
      }

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List ContactForms",
          query: { id: this.openContactForm.id, action: QUERY_ACTIONS_VIEW },
        }).href
      );
    },
    openContactFormEditModal(vehicle) {
      console.log("open vehicle edit modal", vehicle);

      this.closeContactFormModal();
      this.openContactForm = vehicle;
      this.isEditContactFormModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List ContactForms",
          query: {
            id: this.openContactForm.id,
            action: QUERY_ACTIONS_EDIT,
          },
        }).href
      );
    },
    closeContactFormModal() {
      this.isAddContactFormModalOpened = false;
      this.isViewContactFormModalOpened = false;
      this.isEditContactFormModalOpened = false;
      this.openContactForm = null;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List ContactForms",
          query: {},
        }).href
      );
    },

    async deleteContactForm(vehicle) {
      const confirmation = await swal.fire({
        text: this.$t("CONTACT_FORMS.DELETE_THIS_CONTACT_FORM"),
        type: "question",
        customClass: {
          popup: "delete-popup",
        },
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonText:
          this.$t("COMMON.YES") + ", " + this.$t("COMMON.DELETE"),
        confirmButtonClass: "btn base-button btn-primary",
        cancelButtonClass: "btn base-button btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("contactForms/destroy", vehicle.id);
          this.renderKey++;
          this.closeContactFormModal();
          this.$notify({
            type: "success",
            message: this.$t("CONTACT_FORMS.VEHICLE_DELETED"),
          });
        }
      } catch (error) {
        // await this.$showRequestError(error);

        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
  },
};
</script>
