<template>
  <div class="container-fluid">
    <contact-form-form
      ref="form"
      :loading="loading"
      :contact-form-data="contactForm"
      :form-errors="formErrors"
      @contactFormSubmitted="handleSubmit"
      @formChanged="() => (alertLeave = true)"
    />
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import { Option, Select } from "element-ui";
import alertLeave from "@/mixins/alert-leave-mixin";
import defaultContactForm from "../defaultContactForm";
import ContactFormForm from "../partials/ContactFormForm.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    ContactFormForm,
  },

  mixins: [alertLeave],

  data() {
    const contactForm = cloneDeep(defaultContactForm);

    return {
      contactForm,
      formErrors: null,
      loading: false,
    };
  },

  created() {},

  methods: {
    async handleSubmit(contactForm) {
      this.loading = true;

      const contactFormData = cloneDeep(contactForm);

      delete contactFormData.id;

      try {
        await this.$store.dispatch("contactForms/add", contactFormData);

        this.$notify({
          type: "success",
          message: this.$t("CONTACT_FORMS.CONTACT_FORM_ADDED"),
        });

        this.contactForm = this.$store.getters["contactForms/contactForm"];

        this.$emit("onViewContactForm", this.contactForm, true);
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });

        this.formErrors = error.response?.data?.errors ?? [];
      }

      this.loading = false;
    },
  },
};
</script>
